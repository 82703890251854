<template>
  <div class="right-bar">
    <div class="right-bar__back" @click="changeToggleLogin(false)"/>
    <div class="right-bar__content">
      <div class="right-bar__close" @click="changeToggleLogin(false)"></div>`
      <div class="right-bar__title col-md-10">
        Log In
      </div>
      <div class="right-bar__form col-md-6">
        <div class="right-bar__form-block row">
          <label class="input-block col-md-12">
            <input type="email" v-model="$v.form.email.$model"  @keyup.enter="authUser()"><span/>
            <span class="input-block__name" :class="form.email.length ?'active' : null" >E-mail</span>
            <small class="input-block__error" v-if="$v.form.email.$dirty && $v.form.email.$error">email must be valid</small>
          </label>
          <label class="input-block col-md-12">
            <input type="password" v-model="$v.form.password.$model" @keyup.enter="authUser()"><span/>
            <span class="input-block__name" :class="form.password.length ?'active' : null" >password</span>
            <small class="input-block__error" v-if="$v.form.password.$dirty && !$v.form.password.required">password is required</small>
          </label>
          <p class="ml-3"><a href="#" class="link" @click.prevent="openForgotPassword()">Forgot Password</a></p>
        </div>
        <div class="right-bar__form-block row">
          <div class="col-md-12 d-flex align-center">
            <button class="btn grey mr-3" @click="authUser()">Auth</button>
            <span>If you don't have an account - <a class="link" href="#" @click.prevent="openRegister()">REGISTER</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  data(){
    return{
      form:{
        email:'',
        password:'',
      }
    }
  },
  validations: {
    form: {
      email: {
        email,
        required
      },
      password: {
        required
      },
    }
  },
  methods:{
    authUser(){
      this.changeToggleLoading(true);
      this.$v.$touch();
      if(!this.$v.form.$error){
        this.sendAuth(this.form).then(res=>{
          if(res.status){
            this.getProfile().then(res=>{
              this.changeToggleLoading(false);
              if(!res.status){
                this.$toasted.error(res.data,{
                  duration: 3000
                });
              }
            });
            this.$router.push({name:'Dashboard'});
          }else{
            this.changeToggleLoading(false);
            this.toggleAlertPopup({
              status: true,
              content: {title:'Error', text: res.data.message, type:"Error!"}
            });
          }
        })
      }else{
        this.changeToggleLoading(false);
      }
    },
    openRegister(){
      this.changeToggleLogin(false);
      this.changeToggleRegistration(true);
    },
    openForgotPassword(){
      this.changeToggleLogin(false);
      this.changeToggleForgotPassword(true);
    },
    ...mapActions({
      sendAuth:'auth/sendAuth',
      getProfile: 'profile/getProfile'
    }),
    ...mapMutations({
      changeToggleRegistration: 'general/changeToggleRegistration',
      changeToggleLogin: 'general/changeToggleLogin',
      changeToggleForgotPassword: 'general/changeToggleForgotPassword',
      toggleAlertPopup: 'general/toggleAlertPopup',
      changeToggleLoading: 'general/changeToggleLoading',
    })
  }
}
</script>
